function _typeof(obj) {
	"@babel/helpers - typeof";
	return (_typeof =
	  "function" == typeof Symbol && "symbol" == typeof Symbol.iterator
		? function (obj) {
			return typeof obj;
		  }
		: function (obj) {
			return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
		  })(obj);
  }
  !(function (con) {
	for (var method, dummy = function () {}, methods = "assert,count,debug,dir,dirxml,error,exception,group,groupCollapsed,groupEnd,info,log,markTimeline,profile,profileEnd,time,timeEnd,trace,warn".split(","); (method = methods.pop()); )
	  con[method] = con[method] || dummy;
  })((window.console = window.console || {})),
	(function (global) {
	  var apple_phone = /iPhone/i,
		apple_ipod = /iPod/i,
		apple_tablet = /iPad/i,
		android_phone = /(?=.*\bAndroid\b)(?=.*\bMobile\b)/i,
		android_tablet = /Android/i,
		amazon_phone = /(?=.*\bAndroid\b)(?=.*\bSD4930UR\b)/i,
		amazon_tablet = /(?=.*\bAndroid\b)(?=.*\b(?:KFOT|KFTT|KFJWI|KFJWA|KFSOWI|KFTHWI|KFTHWA|KFAPWI|KFAPWA|KFARWI|KFASWI|KFSAWI|KFSAWA)\b)/i,
		windows_phone = /IEMobile/i,
		windows_tablet = /(?=.*\bWindows\b)(?=.*\bARM\b)/i,
		other_blackberry = /BlackBerry/i,
		other_blackberry_10 = /BB10/i,
		other_opera = /Opera Mini/i,
		other_chrome = /(CriOS|Chrome)(?=.*\bMobile\b)/i,
		other_firefox = /(?=.*\bFirefox\b)(?=.*\bMobile\b)/i,
		seven_inch = new RegExp("(?:Nexus 7|BNTV250|Kindle Fire|Silk|GT-P1000)", "i"),
		match = function (regex, userAgent) {
		  return regex.test(userAgent);
		},
		IsMobileClass = function (userAgent) {
		  var ua = userAgent || navigator.userAgent,
			tmp = ua.split("[FBAN");
		  if (
			(void 0 !== tmp[1] && (ua = tmp[0]),
			void 0 !== (tmp = ua.split("Twitter"))[1] && (ua = tmp[0]),
			(this.apple = {
			  phone: match(apple_phone, ua),
			  ipod: match(apple_ipod, ua),
			  tablet: !match(apple_phone, ua) && match(apple_tablet, ua),
			  device: match(apple_phone, ua) || match(apple_ipod, ua) || match(apple_tablet, ua),
			}),
			(this.amazon = { phone: match(amazon_phone, ua), tablet: !match(amazon_phone, ua) && match(amazon_tablet, ua), device: match(amazon_phone, ua) || match(amazon_tablet, ua) }),
			(this.android = {
			  phone: match(amazon_phone, ua) || match(android_phone, ua),
			  tablet: !match(amazon_phone, ua) && !match(android_phone, ua) && (match(amazon_tablet, ua) || match(android_tablet, ua)),
			  device: match(amazon_phone, ua) || match(amazon_tablet, ua) || match(android_phone, ua) || match(android_tablet, ua),
			}),
			(this.windows = { phone: match(windows_phone, ua), tablet: match(windows_tablet, ua), device: match(windows_phone, ua) || match(windows_tablet, ua) }),
			(this.other = {
			  blackberry: match(other_blackberry, ua),
			  blackberry10: match(other_blackberry_10, ua),
			  opera: match(other_opera, ua),
			  firefox: match(other_firefox, ua),
			  chrome: match(other_chrome, ua),
			  device: match(other_blackberry, ua) || match(other_blackberry_10, ua) || match(other_opera, ua) || match(other_firefox, ua) || match(other_chrome, ua),
			}),
			(this.seven_inch = match(seven_inch, ua)),
			(this.any = this.apple.device || this.android.device || this.windows.device || this.other.device || this.seven_inch),
			(this.phone = this.apple.phone || this.android.phone || this.windows.phone),
			(this.tablet = this.apple.tablet || this.android.tablet || this.windows.tablet),
			"undefined" == typeof window)
		  )
			return this;
		},
		instantiate = function () {
		  var IM = new IsMobileClass();
		  return (IM.Class = IsMobileClass), IM;
		};
	  "undefined" != typeof module && module.exports && "undefined" == typeof window
		? (module.exports = IsMobileClass)
		: "undefined" != typeof module && module.exports && "undefined" != typeof window
		? (module.exports = instantiate())
		: "function" == typeof define && define.amd
		? define("isMobile", [], (global.isMobile = instantiate()))
		: (global.isMobile = instantiate());
	})(this);
  var Five9Modules = {},
	Five9SocialWidget = (function () {
	  var SharedProactive,
		ChatModel,
		EmailModel,
		Persist,
		validateOptions = function (options) {
		  if ("string" != typeof options.tenant) throw new Error("Must specify a tenant");
		  if (("object" === _typeof(options.profiles) && options.profiles.length && (options.profiles = options.profiles.join(",")), "string" != typeof options.profiles)) throw new Error("Must specify profiles");
		  options.rootUrl = options.rootUrl || "";
		},
		getParams = function (options) {
		  var params = "";
		  for (var key in options)
			if (options.hasOwnProperty(key)) {
			  var val = options[key];
			  null !== val &&
				void 0 !== val &&
				"undefined" !== val &&
				"" !== val &&
				"function" != typeof val &&
				"rootUrl" !== key &&
				"type" !== key &&
				"profile" !== key &&
				("object" === _typeof(val) && (val = JSON.stringify(val)), (params += "&" + key + "=" + encodeURIComponent(val)));
			}
		  return params.substr(1);
		},
		loadChatData = function () {
		  return Persist.loadData(ChatModel.compositeKey());
		},
		buildChatUrl = function (options) {
		  var rootUrl = options.rootUrl,
			chatModelSaved = loadChatData();
		  chatModelSaved && chatModelSaved.session && (options.playSoundOnMessage = !!chatModelSaved.session.playSoundOnMessage && chatModelSaved.session.playSoundOnMessage);
		  var url = encodeURI(rootUrl + "ChatConsole/index.html?") + getParams(options);
		  return url && url.length > 2e3 ? console.warn("chat url length is: ", url.length) : console.log("chat url length is: ", url.length), url;
		},
		buildEmailUrl = function (options) {
		  var rootUrl = options.rootUrl,
			url = encodeURI(rootUrl + "EmailConsole/index.html?") + getParams(options);
		  return url && url.length > 2e3 ? console.warn("email url length is: ", url.length) : console.log("email url length is: ", url.length), url;
		},
		buildConsumerPowerUrl = function (options) {
		  var rootUrl = options.rootUrl,
			url = encodeURI(rootUrl + "ConsumerPowerConsole/index.html?") + getParams(options);
		  return url && url.length > 2e3 ? console.warn("consumer power url length is: ", url.length) : console.log("consumer power length is: ", url.length), url;
		},
		openChat = function (options, pollClose) {
		  options = options || {};
		  var persistedSessionId,
			persistedData = Persist.loadData(ChatModel.compositeKey()),
			chatOptions = Five9Modules.SharedProactive.sessionData.chatOptions;
		  persistedData && persistedData.session && persistedData.session.id && (persistedSessionId = persistedData.session.id),
			chatOptions && chatOptions.sessionId && chatOptions.sessionId === persistedSessionId && (options = chatOptions),
			validateOptions(options);
		  var url = buildChatUrl(options);
		  return console.log("openChat", url), openWindow(url, pollClose);
		},
		loadCssFile = function (cssPath, cb) {
		  var url, rts, nonce, cacheURL;
		  (url = cssPath), (rts = /([?&])_=[^&]*/), (nonce = Date.now()), (cacheURL = url), (cssPath = url = rts.test(cacheURL) ? cacheURL.replace(rts, "$1_=" + nonce++) : cacheURL + (/\?/.test(cacheURL) ? "&" : "?") + "_=" + nonce++);
		  var link = document.createElement("link");
		  (link.rel = "stylesheet"),
			(link.type = "text/css"),
			(link.href = cssPath),
			(link.media = "all"),
			"function" == typeof cb &&
			  link.addEventListener(
				"load",
				function (e) {
				  cb(null, e);
				},
				!1
			  ),
			document.getElementsByTagName("head")[0].appendChild(link);
		},
		openWindow = function (url, pollClose) {
		  try {
			if (Five9SocialWidget.WindowRef && !Five9SocialWidget.WindowRef.closed) return Five9SocialWidget.WindowRef.focus(), pollClose && startWindowPoll(), !0;
			var topLeft = popupRight(Five9SocialWidget.WindowWidth, Five9SocialWidget.WindowHeight),
			  props = "width=" + Five9SocialWidget.WindowWidth + ",height=" + Five9SocialWidget.WindowHeight + ",left=" + topLeft.left + ",top=" + topLeft.top;
			return (
			  (props += ",location=no,menubar=no,resizable=yes,scrollbars=no,status=no,titlebar=no,toolbar=no"),
			  (Five9SocialWidget.WindowRef = window.open("", Five9SocialWidget.WindowName, props)),
			  "about:blank" === Five9SocialWidget.WindowRef.location.href ? (Five9SocialWidget.WindowRef = window.open(url, Five9SocialWidget.WindowName, props)) : Five9SocialWidget.WindowRef.focus(),
			  pollClose && startWindowPoll(),
			  !0
			);
		  } catch (err) {
			return console.error("Exception during openWindow", err), !1;
		  }
		},
		popupRight = function (w, h) {
		  var dualScreenLeft = void 0 !== window.screenLeft ? window.screenLeft : screen.left,
			dualScreenTop = void 0 !== window.screenTop ? window.screenTop : screen.top,
			width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
		  return {
			top: (window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height) - 50 - h / 2 + dualScreenTop,
			left: width - 0 - w / 2 + dualScreenLeft,
		  };
		},
		removeNodeAndListeners = function (el) {
		  el && el.parentNode && ((el.src = "about:blank"), el.parentNode.removeChild(el));
		},
		setStyle = function (el, props) {
		  if (el) for (var prop in props) el.style[prop] = props[prop];
		},
		windowMinimize = function () {
		  setStyle(Five9SocialWidget.frame.querySelector(".five9-frame-minimized"), { display: "block" }), setStyle(Five9SocialWidget.frame.querySelector(".five9-frame-full"), { display: "none", visibility: "hidden" });
		},
		addEmbeddedFrame = function (options) {
		  console.log("addEmbeddedFrame options: ", options);
		  var url = "email" === options.type ? buildEmailUrl(options) : "consumer" === options.type ? buildConsumerPowerUrl(options) : buildChatUrl(options);
		  (console.log("addEmbeddedFrame", url), Five9SocialWidget.frame.querySelector("#embedded-frame")) ||
			(!(function (selector, template) {
			  var parent = document.body.querySelector(selector),
				frame = document.createElement("div");
			  (frame.innerHTML = template), parent.appendChild(frame);
			})(".five9-frame-full", '<iframe id="embedded-frame" src="' + url + '" ' + ('style="border:none;" width="' + Five9SocialWidget.WindowWidth + '" height="' + Five9SocialWidget.WindowHeight + '"') + "></iframe>"),
			console.log("WIDGET: add embedded frame"));
		},
		removeEmbeddedFrame = function () {
		  var embeddedFrame = Five9SocialWidget.frame.querySelector("#embedded-frame");
		  embeddedFrame && (removeNodeAndListeners(embeddedFrame), console.log("WIDGET: remove embedded frame"));
		},
		loadDetailData = function (options) {
		  return "chat" === options.type ? loadChatData() : Persist.loadData(EmailModel.Key);
		},
		setState = function (state) {
		  (Five9SocialWidget.data.state = state), Persist.saveData(Five9SocialWidget.PersistKey, Five9SocialWidget.data);
		},
		openWindowFromOptions = function (options) {
		  "email" === options.type
			? (function (options, pollClose) {
				validateOptions((options = options || {}));
				var url = buildEmailUrl(options);
				console.log("openEmail", url), openWindow(url, pollClose);
			  })(options, !0)
			: "consumer" === options.type
			? (function (options, pollClose) {
				validateOptions((options = options || {}));
				var url = buildConsumerPowerUrl(options);
				console.log("openConsumerPower", url), openWindow(url, pollClose);
			  })(options, !0)
			: openChat(options, !0);
		},
		onMaximizeClicked = function (options) {
		  SharedProactive && SharedProactive.inProgress() && (console.info("Customer triggered manual chat.  abandon preview chat"), SharedProactive.abandonPreview(), SharedProactive.hideChatOffer()),
			"minimized" === Five9SocialWidget.data.state
			  ? (console.log("WIDGET: maximize widget"), Five9SocialWidget.isMobile ? (openWindowFromOptions(options), setState("popout")) : clickMaximize(options))
			  : "popout" === Five9SocialWidget.data.state && (console.log("WIDGET: widget has popout.  attempt to re-use / open window"), openWindowFromOptions(options));
		},
		clickMaximize = function (options) {
		  addEmbeddedFrame(options),
			setStyle(Five9SocialWidget.frame.querySelector(".five9-frame-minimized"), { display: "none" }),
			setStyle(Five9SocialWidget.frame.querySelector(".five9-frame-full"), { display: "block", visibility: "visible", height: Five9SocialWidget.WindowHeight + Five9SocialWidget.HeaderHeight + "px" }),
			setState("maximized");
		},
		clickMinimize = function () {
		  windowMinimize(), setState("minimized");
		},
		pollWindowOpen = function () {
		  Five9SocialWidget.WindowRef &&
			Five9SocialWidget.WindowRef.closed &&
			(setState("minimized"), Five9SocialWidget._pollWindowOpenTimerId && (clearInterval(Five9SocialWidget._pollWindowOpenTimerId), (Five9SocialWidget._pollWindowOpenTimerId = null)), console.log("WIDGET: popout was closed"));
		},
		startWindowPoll = function () {
		  Five9SocialWidget._pollWindowOpenTimerId = setInterval(pollWindowOpen, 200);
		},
		onReceiveMessage = function (e) {
		  try {
			if (e && "string" == typeof e.data) {
			  var messageData = e.data,
				message = JSON.parse(messageData);
			  message && ("minimize" === message.action ? ("popout" !== Five9SocialWidget.data.state && removeEmbeddedFrame(), clickMinimize()) : "set-localstorage" === message.action && Persist.saveData(message.key, message.value));
			}
		  } catch (err) {}
		},
		enableDrageAndDrop = function () {
		  var selected = null,
			x_pos = 0,
			y_pos = 0,
			x_elem = 0,
			y_elem = 0;
		  (document.getElementById("five9-frame-full").onmousedown = function () {
			return (x_elem = x_pos - (selected = this).offsetLeft), (y_elem = y_pos - selected.offsetTop), !1;
		  }),
			(document.onmousemove = function (e) {
			  (x_pos = document.all ? window.event.clientX : e.pageX),
				(y_pos = document.all ? window.event.clientY : e.pageY),
				null !== selected && ((selected.style.left = x_pos - x_elem + "px"), (selected.style.top = y_pos - y_elem + "px"));
			}),
			(document.onmouseup = function () {
			  selected = null;
			});
		};
	  return {
		WindowRef: null,
		WindowName: "Five9SocialWidget",
		WindowWidth: 320,
		WindowHeight: 550,
		HeaderHeight: 40,
		PersistKey: "f9-social-widget",
		removeEmbeddedFrame: removeEmbeddedFrame,
		data: { state: "minimized", type: null },
		addWidget: function (options) {
		  if (!Five9SocialWidget.widgetAdded) {
			if ((((options = options || {}).namespace = options.namespace || window.location.host), validateOptions(options), !options.ga && window.dataLayer && "function" == typeof window.dataLayer.find)) {
			  var googleTagId = window.dataLayer.find(function (layer) {
				return "config" === layer[0];
			  });
			  googleTagId && googleTagId[1] && (options.ga = googleTagId[1]);
			}
			if (
			  ((Five9SocialWidget.options = options),
			  (ChatModel = Five9Modules.ChatModel),
			  (EmailModel = Five9Modules.EmailModel),
			  (Persist = Five9Modules.Persist),
			  (SharedProactive = Five9Modules.SharedProactive),
			  (ChatModel && EmailModel && Persist && SharedProactive) || console.error("Fatal error: missing modules", Five9Modules),
			  !SharedProactive.supportsFeatures())
			)
			  return !1;
			var displayWidget = function () {
			  ChatModel.setDomainKey(options.tenant), ChatModel.setNamespace(options.namespace), (options.rootUrl = SharedProactive.addTrailingSlash(options.rootUrl));
			  var mobile = !!isMobile && isMobile.any;
			  (Five9SocialWidget.isMobile = mobile), (Five9SocialWidget.widgetAdded = !0), window.addEventListener("message", onReceiveMessage, !1), console.info("addWidget", { rootUrl: options.rootUrl, isMobile: mobile });
			  var buttonClass = "email" === options.type ? "five9-email-button" : "five9-chat-button",
				buttonText = "email" === options.type ? "Email" : "consumer" === options.type ? "Contact Us" : "Chat",
				frameTemplate =
				  '<div class="five9-frame-minimized"><div class="five9-header"><div id="five9-maximize-button" class="' +
				  buttonClass +
				  '"><span class="five9-icon"></span><span class="five9-text">' +
				  buttonText +
				  '</span></div></div></div><div id="five9-frame-full" class="five9-frame-full"><div class="five9-header"><div id="five9-minimize-button" class="' +
				  buttonClass +
				  '"><div class="five9-icon"></div><div class="five9-text">' +
				  buttonText +
				  '</div><div id="five9-minimize-icon" title="Click to minimize"></div><div id="five9-popout-button" title="Click to popout into a new window"></div></div></div></div>',
				frame = document.createElement("div");
			  if (((frame.innerHTML = frameTemplate), frame.classList.add("five9-frame"), Five9SocialWidget.cssLoaded)) frame.setAttribute("style", "width: " + Five9SocialWidget.WindowWidth + "px;");
			  else {
				var cssPath = options.rootUrl + "SocialWidget/five9-social-widget.css";
				loadCssFile(cssPath, function () {
				  setStyle(frame, { display: "block" }), "function" == typeof options.done && options.done();
				}),
				  frame.setAttribute("style", "width: " + Five9SocialWidget.WindowWidth + "px;display:none;"),
				  (Five9SocialWidget.cssLoaded = !0);
			  }
			  document.body.appendChild(frame), (Five9SocialWidget.frame = frame);
			  var data = Persist.loadData(Five9SocialWidget.PersistKey);
			  if ((data && (Five9SocialWidget.data = data), (Five9SocialWidget.data.type = options.type), "maximized" === Five9SocialWidget.data.state)) {
				var detailData = loadDetailData(options);
				detailData && "Finished" === detailData.step
				  ? (console.log("WIDGET: widget frame is finished.  no need to re-maximize"), setState("minimized"))
				  : (console.log("WIDGET: widget was maximized.  re-maximize"), clickMaximize(options));
			  } else "popout" === Five9SocialWidget.data.state && console.log("WIDGET: widget was popout.  no need to do anything until customer clicks");
			  var maximizeButton = frame.querySelector("#five9-maximize-button");
			  maximizeButton &&
				maximizeButton.addEventListener("click", function (e) {
				  onMaximizeClicked(options);
				});
			  var minimizeButton = frame.querySelector("#five9-minimize-icon");
			  minimizeButton &&
				minimizeButton.addEventListener("click", function (e) {
				  console.log("WIDGET: attempt to minimize widget"),
					(detailData = loadDetailData(options)),
					"consumer" === options.type
					  ? (clickMinimize(), console.log("WIDGET: minimize widget"))
					  : "chat" === options.type && ChatModel.allowMinimize(detailData)
					  ? (clickMinimize(), console.log("WIDGET: minimize widget"))
					  : "email" === options.type && EmailModel.allowMinimize(detailData) && (clickMinimize(), console.log("WIDGET: minimize widget"));
				});
			  var popoutButton = frame.querySelector("#five9-popout-button");
			  popoutButton &&
				popoutButton.addEventListener("click", function (e) {
				  return (
					e.stopPropagation(),
					(function (options) {
					  windowMinimize(), removeEmbeddedFrame(), openWindowFromOptions(options), setState("popout");
					})(options),
					console.log("WIDGET: popout the widget"),
					!1
				  );
				}),
				options.allowDragAndDrop && enableDrageAndDrop();
			};
			"chat" === options.type && options.hideDuringAfterHours
			  ? Five9.Api.EstimatedWaitTime.areAnyProfilesOpenForBusiness({ tenant: options.tenant, profiles: options.profiles.split(","), rootUrl: options.rootUrl.replace("consoles/", "") }, function (error, response) {
				  error ? console.error("Five9: Error checking business hours", error) : response.length && displayWidget();
				})
			  : displayWidget();
		  }
		},
		removeWidget: function () {
		  Five9SocialWidget.widgetAdded = !1;
		  var frame = Five9SocialWidget.frame;
		  frame && (delete Five9SocialWidget.frame, removeNodeAndListeners(frame));
		},
		initializeProactiveChat: function (options) {
		  if (!Five9SocialWidget.proactiveInitialized) {
			if ((validateOptions((options = options || {})), (SharedProactive = Five9Modules.SharedProactive) || console.error("Fatal error: missing modules", Five9Modules), !SharedProactive.supportsFeatures())) return !1;
			if (!Five9SocialWidget.cssLoaded) {
			  var cssPath = options.rootUrl + "SocialWidget/five9-social-widget.css";
			  loadCssFile(cssPath), (Five9SocialWidget.cssLoaded = !0);
			}
			(options.analyticsProvider = 2),
			  (options.onAccept = function (chatOptions) {
				Five9SocialWidget.widgetAdded ? onMaximizeClicked(chatOptions) : openChat(chatOptions, !1);
			  }),
			  (options.onReject = function () {}),
			  SharedProactive.initialize(options),
			  (Five9SocialWidget.proactiveInitialized = !0);
		  }
		},
		loadOffers: function (profileName, onSuccess) {
		  if ("string" != typeof profileName) throw new Error("Must specify a profileName");
		  onSuccess = onSuccess || function () {};
		  SharedProactive.loadOffers(function (response) {
			console.info("onLoadOffersSuccess", response);
			for (var selectedProfile = null, i = 0; i < response.length; i++)
			  if (response[i].name === profileName) {
				selectedProfile = response[i];
				break;
			  }
			selectedProfile
			  ? (selectedProfile.enablePreviewChat ? console.log("Preview chat enabled for " + profileName) : console.log("Preview chat not enabled for " + profileName), onSuccess(selectedProfile))
			  : console.error("onLoadOffersSuccess() campaign not found", profileName);
		  });
		},
		triggerOffer: function (offerOptions) {
		  return (
			console.log("five9-social-widget:triggerOffer", offerOptions),
			"minimized" === Five9SocialWidget.data.state ? (SharedProactive.triggerOffer(offerOptions), !0) : (console.warn("Customer is starting a manual chat.  offer not triggered"), !1)
		  );
		},
		maximizeChat: function (chatOptions) {
		  Five9SocialWidget.widgetAdded ? onMaximizeClicked(chatOptions) : console.error("Widget must be added to maximize chat");
		},
		processOfferAccepted: function () {
		  SharedProactive.triggerCustomerEngageAccept();
		},
		processOfferRefused: function () {
		  SharedProactive.triggerCustomerEngageReject();
		},
	  };
	})();
  !(function () {
	var ProactivePersist = {
	  Version: 1,
	  Key: "f9-offers",
	  loadData: function () {
		try {
		  var data = sessionStorage.getItem(ProactivePersist.Key);
		  if (data) {
			try {
			  data = JSON.parse(data);
			} catch (err) {}
			if (data.version === ProactivePersist.Version) return console.info("Persist:loadData()", ProactivePersist.Key, data), delete data.version, delete data.date, data;
		  }
		} catch (err) {}
	  },
	  saveData: function (data) {
		try {
		  (data.date = Date.now()), (data.version = ProactivePersist.Version), sessionStorage.setItem(ProactivePersist.Key, JSON.stringify(data)), console.info("Persist:saveData()", ProactivePersist.Key, data);
		} catch (err) {}
	  },
	};
	Five9Modules && (Five9Modules.ProactivePersist = ProactivePersist);
  })(),
	(function () {
	  var MessageTypes,
		ProactivePersist,
		NotificationRootSelector = "five9-notification",
		NotificationActiveClass = "active",
		ModalRootSelector = "five9-modal",
		TokenId = !1,
		FarmId = !1,
		OnEngageAcceptCallback = function () {},
		OnEngageRejectCallback = function () {},
		OnOfferCallback = function () {},
		getValueFromFields = function (searchKey, fields) {
		  for (var key in fields) if (key === searchKey && fields[key].value && SharedProactive.nonEmptyString(fields[key].value)) return fields[key].value;
		  return "";
		},
		formatTime = function (d) {
		  var hours = d.getHours();
		  hours = hours % 12 > 0 ? hours % 12 : 12;
		  var mins = d.getMinutes();
		  return hours + ":" + (mins = mins < 10 ? "0" + mins : mins) + " " + (d.getHours() > 12 ? "pm" : "am");
		},
		Requests_loadOffers = function (options, onSuccess, onError) {
		  if ("string" != typeof (options = options || {}).restAPI) throw new Error("loadOffers() Must specify a restAPI");
		  if ("string" != typeof options.tenant) throw new Error("loadOffers() Must specify a tenant");
		  (options.restAPI = SharedProactive.addTrailingSlash(options.restAPI)), (options.rootUrl = SharedProactive.addTrailingSlash(options.rootUrl));
		  var xhrOptions = { url: options.restAPI + SharedProactive.APIPath + "orgs/-1/chatoffers/" + options.tenant, verb: "GET" };
		  SharedProactive.Mock
			? setTimeout(function () {
				(onSuccess =
				  onSuccess ||
				  function () {})([{ name: "user1_inbound_5555550001", id: 1459464962, tenantId: "421", groupId: "1631", consecutivePagesOnly: 0, chatOfferCondition: "Amount_of_time_spent", proactiveChatQuestion: "Do you need help?", proactiveChatTimeSpent: 10, proactiveChatHoverDuration: 10, proactiveChatNumberOfPages: 1, proactiveChatOfferTimeout: 10, proactiveChatNumberOfOffer: 30, proactiveEstimatedWaitTime: 1, enablePreviewChat: 1, previewContactEditAllowed: 0 }]);
			  }, 1e3)
			: SharedProactive.xhr(xhrOptions, onSuccess, onError);
		},
		Requests_status = function (options, onSuccess, onError) {
		  if ("string" != typeof (options = options || {}).restAPI) throw new Error("status() Must specify a restAPI");
		  if (void 0 === options.tenantId) throw new Error("status() Must specify a tenantId");
		  if (void 0 === options.groupId) throw new Error("status() Must specify a groupId");
		  (options.restAPI = SharedProactive.addTrailingSlash(options.restAPI)), (options.rootUrl = SharedProactive.addTrailingSlash(options.rootUrl));
		  var xhrOptions = { url: options.restAPI + SharedProactive.APIPath + SharedProactive.formatString("orgs/estimatedwaittime/1000?tenantName{0}=&campaignName={1}", options.tenant, options.profiles), verb: "GET" };
		  SharedProactive.Mock
			? setTimeout(function () {
				(onSuccess = onSuccess || function () {})({ resultCode: 0, ewt: 7, mediaType: 1e3, groupId: 1631, orgId: 421 });
			  }, 1e3)
			: SharedProactive.xhr(xhrOptions, onSuccess, onError);
		},
		Requests_openSession = function (options, onSuccess, onError) {
		  if ("string" != typeof (options = options || {}).restAPI) throw new Error("openSession() Must specify a restAPI");
		  if ("string" != typeof options.tenant) throw new Error("openSession() Must specify a tenant");
		  var url = options.restAPI + SharedProactive.APIPath + "auth/anon?cookieless=true&clientApp=proactiveChatConsole",
			payload = { tenantName: options.tenant, five9SessionId: null };
		  console.info("openSession", { url: url, payload: payload });
		  var xhrOptions = { url: url, verb: "POST", payload: payload };
		  SharedProactive.Mock
			? setTimeout(function () {
				(onSuccess = onSuccess || function () {})({ tokenId: "token-1", userId: "token-1", orgId: "tenant-1" });
			  }, 1e3)
			: SharedProactive.xhr(xhrOptions, onSuccess, onError);
		},
		Requests_openChatPreview = function (options, onSuccess, onError) {
		  if ("string" != typeof (options = options || {}).restAPI) throw new Error("openChatPreview() Must specify a restAPI");
		  if ("string" != typeof options.sessionId) throw new Error("openChatPreview() Must specify a sessionId");
		  if ("string" != typeof options.profile) throw new Error("openChatPreview() Must specify a profile");
		  (TokenId = options.sessionId), (FarmId = options.farmId);
		  var restAPI = options.restAPI + SharedProactive.APIPath,
			sessionId = options.sessionId,
			profile = options.profile,
			email = getValueFromFields("email", options.fields);
		  SharedProactive.nonEmptyString(email) || ((email = SharedProactive.generateAnonEmail()), options.fields && options.fields.email && (options.fields.email.value = email));
		  var name = getValueFromFields("name", options.fields);
		  SharedProactive.nonEmptyString(name) || (name = "");
		  var groupId = options.groupId;
		  SharedProactive.nonEmptyString(groupId) || (groupId = "-1");
		  var customFields = [];
		  customFields.push({ key: "Subject", value: profile, analyze: 0 }),
			customFields.push({ key: "Name", value: name, analyze: 0 }),
			customFields.push({ key: "Email", value: email, analyze: 0 }),
			customFields.push({ key: "Question", value: "", analyze: 1 }),
			customFields.push({ key: "f9-browser", value: JSON.stringify({ userAgent: navigator.userAgent, language: navigator.language }), analyze: 0 }),
			options.history ? (console.info("preview chat history", options.history), customFields.push({ key: "f9-history", value: JSON.stringify(options.history), analyze: 0 })) : console.info("no history supplied for preview chat"),
			"object" === _typeof(options.customFields) && (customFields = customFields.concat(options.customFields));
		  var customVariables = options.customVariables;
		  if ("object" === _typeof(customVariables))
			for (var id in customVariables)
			  if (customVariables.hasOwnProperty(id)) for (var key in customVariables[id]) customVariables[id].hasOwnProperty(key) && customFields.push({ key: id + "." + key, value: customVariables[id][key], analyze: 0 });
		  var timezoneOffset,
			offsetHour,
			chatRequest = {};
		  (chatRequest.campaign = profile),
			(chatRequest.groupId = groupId),
			(chatRequest.name = name),
			(chatRequest.email = email),
			(chatRequest.customFields = customFields),
			(chatRequest.analyticsProvider = options.analyticsProvider),
			(chatRequest.proactiveChat = !0),
			(chatRequest.timezone = ((timezoneOffset = new Date().getTimezoneOffset()), (offsetHour = String((Math.abs(timezoneOffset) / 60) * 100).padStart(4, "0")), "GMT" + (timezoneOffset >= 0 ? "-" : "+") + offsetHour));
		  var url = restAPI + "agents/" + sessionId + "/interactions/client_chat_preview";
		  console.info("openChatPreview", { url: url, chatRequest: chatRequest });
		  var xhrOptions = { url: url, verb: "POST", payload: chatRequest };
		  SharedProactive.Mock
			? ((onSuccess = onSuccess || function () {}),
			  setTimeout(function () {
				onSuccess({
				  loggedInProfileAgent: {
					profileId: "1631",
					profileName: "user1_inbound_5555550001",
					agentLoggedIn: !0,
					noServiceMessage: "We are currently unable to service your request. Please contact us during normal business hours.",
				  },
				  profileSurvey: { profileId: "2504", templateId: 3, templateQuestion: "Custom survey question", templateThankyouMessage: "Custom thank you message", enableSurvey: 0 },
				});
			  }, 1e3))
			: SharedProactive.xhr(xhrOptions, onSuccess, onError);
		},
		Requests_acceptChatPreviewOffer = function (options, onSuccess, onError) {
		  if ("string" != typeof (options = options || {}).restAPI) throw new Error("acceptChatPreviewOffer() Must specify a restAPI");
		  if ("string" != typeof options.sessionId) throw new Error("acceptChatPreviewOffer() Must specify a sessionId");
		  var restAPI = options.restAPI + SharedProactive.APIPath,
			sessionId = options.sessionId,
			url = restAPI + "agents/" + sessionId + "/interactions/" + sessionId + "/client_accept_offer",
			name = getValueFromFields("name", options.fields),
			email = getValueFromFields("email", options.fields),
			payload = { campaign: options.profile, groupId: "-1", customFields: [], name: name, email: email };
		  console.info("acceptChatPreviewOffer", { url: url, payload: payload }), "function" == typeof gtag && gtag("event", "Preview Chat Offer Accepted", { event_category: "Five9", event_label: options.profile });
		  var xhrOptions = { url: url, verb: "PUT", payload: payload };
		  SharedProactive.Mock
			? setTimeout(function () {
				(onSuccess = onSuccess || function () {})({});
			  }, 1e3)
			: SharedProactive.xhr(xhrOptions, onSuccess, onError);
		},
		Requests_rejectChatPreviewOffer = function (options, onSuccess, onError) {
		  if ("string" != typeof (options = options || {}).restAPI) throw new Error("rejectChatPreviewOffer() Must specify a restAPI");
		  if ("string" != typeof options.sessionId) throw new Error("rejectChatPreviewOffer() Must specify a sessionId");
		  var restAPI = options.restAPI + SharedProactive.APIPath,
			sessionId = options.sessionId,
			url = restAPI + "agents/" + sessionId + "/interactions/" + sessionId + "/client_reject_offer",
			payload = {};
		  console.info("rejectChatPreviewOffer", { url: url, payload: payload }), "function" == typeof gtag && gtag("event", "Preview Chat Offer Rejected", { event_category: "Five9", event_label: options.profile });
		  var xhrOptions = { url: url, verb: "PUT", payload: payload };
		  SharedProactive.Mock
			? setTimeout(function () {
				(onSuccess = onSuccess || function () {})({});
			  }, 1e3)
			: SharedProactive.xhr(xhrOptions, onSuccess, onError);
		},
		engageChatOffer = function (options) {
		  if (((TokenId = options.sessionId), (FarmId = options.farmId), (options.onAccept = SharedProactive.triggerCustomerEngageAccept), (options.onReject = SharedProactive.triggerCustomerEngageReject), "function" == typeof gtag)) {
			var chatType = !0 === options.preview ? "Preview" : "Proactive";
			gtag("event", chatType + " Chat Offered", { event_category: "Five9", event_label: options.profile });
		  }
		  "callback" === options.notificationType ? OnOfferCallback(options.question, options.timeout) : "modal" === options.notificationType ? SharedProactive.showChatOfferModal(options) : SharedProactive.showChatOfferNotification(options);
		},
		resume = function () {
		  var options = SharedProactive.sessionData.options,
			chatOptions = SharedProactive.sessionData.chatOptions,
			selectedProfile = SharedProactive.sessionData.selectedProfile,
			terminateChat = function (interactionId) {
			  SharedProactive.sessionData.options.sessionId === interactionId &&
				(console.warn("interaction terminated", interactionId), SharedProactive.closeSocket(), (SharedProactive.sessionData.step = SharedProactive.Steps.LoadOffers), SharedProactive.save(), SharedProactive.hideChatOffer());
			},
			onSocketReceived = function (event) {
			  var response;
			  if (event.data)
				try {
				  var message = JSON.parse(event.data);
				  console.log("onSocketReceived", message);
				  var payload = message.payLoad,
					context = message.context;
				  context && payload && ((payload.messageId = context.messageId), "string" == typeof payload.messageId && payload.messageId.length && (payload.messageId = parseInt(payload.messageId, 10))),
					payload &&
					  (payload.messageId === MessageTypes.PREVIEW_ENGAGE_ITEM
						? (function (response) {
							console.info("onSocketEngageRequest", response),
							  1 === (response = response || {}).engaged
								? ("string" != typeof response.question && console.error("onSocketEngageRequest() error.  API did not return question"),
								  (options.question = response.question),
								  (SharedProactive.sessionData.step = SharedProactive.Steps.Engaged),
								  SharedProactive.save(),
								  engageChatOffer(options))
								: 0 === response.engaged && (this.onServerTerminated && "function" == typeof this.onServerTerminated && this.onServerTerminated(), terminateChat(options.sessionId), OnEngageRejectCallback());
						  })(payload)
						: payload.messageId === MessageTypes.INTERACTION_TERMINATE || payload.messageId === MessageTypes.MSG_CHAT_AGENT_TERMINATE
						? ("string" != typeof (response = (response = payload) || {}).interactionId && console.error("onSocketInteractionTerminated() error.  API did not return interactionId"), terminateChat(response.interactionId))
						: payload.messageId === MessageTypes.MSG_CHAT_AGENT_ACCEPT &&
						  (function (response) {
							"string" != typeof (response = response || {}).interaction && console.error("onSocketAgentAccept() error.  API did not return interaction"), (SharedProactive.sessionData.messages[0] = response);
							var interaction = JSON.parse(response.interaction),
							  options = SharedProactive.sessionData.options;
							(options.ownerId = interaction.ownerId), (options.displayName = interaction.displayName), SharedProactive.save();
						  })(payload));
				} catch (err) {
				  console.error("Unable to parse socket message", err);
				}
			},
			onChatPreviewSuccess = function (response) {
			  console.info("onChatPreviewSuccess", response),
				response && response.loggedInProfileAgent && response.loggedInProfileAgent.agentLoggedIn
				  ? ((chatOptions.preview.survey = response.profileSurvey),
					(response.profileSurvey && !1 !== SharedProactive.nonEmptyString(response.profileSurvey.profileId)) || console.error("profileSurvey does not contain a valid profile id"),
					(chatOptions.preview.profileId = response.profileSurvey ? response.profileSurvey.profileId : ""),
					(SharedProactive.sessionData.step = SharedProactive.Steps.WaitForEngage),
					SharedProactive.save(),
					console.info("begin listen for engage"),
					SharedProactive.openSocket(options, onSocketReceived))
				  : console.warn("No agents are logged in");
			};
		  SharedProactive.sessionData.step === SharedProactive.Steps.OpenSession
			? Requests_openSession(options, function (response) {
				console.info("onSessionSuccess", response),
				  !1 === SharedProactive.nonEmptyString(response.tokenId) && console.error("session results do not contain a valid tokenId"),
				  !1 === SharedProactive.nonEmptyString(response.orgId) && console.error("session results do not contain a valid orgId"),
				  (TokenId = response.tokenId),
				  (FarmId = response.context.farmId),
				  (options.sessionId = TokenId),
				  (options.farmId = FarmId),
				  (options.restAPI = "https://" + response.metadata.dataCenters[0].apiUrls[0].host + "/"),
				  (chatOptions.tokenId = TokenId),
				  (chatOptions.farmId = FarmId),
				  (chatOptions.preview = { tenantId: response.orgId, interactionId: TokenId, previewContactEditAllowed: selectedProfile.previewContactEditAllowed, profile: options.profile }),
				  console.info("sessionId", TokenId),
				  (SharedProactive.sessionData.step = SharedProactive.Steps.StartChatPreview),
				  (SharedProactive.sessionData.selectedProfile = selectedProfile),
				  SharedProactive.save(),
				  Requests_openChatPreview(options, onChatPreviewSuccess);
			  })
			: SharedProactive.sessionData.step === SharedProactive.Steps.StartChatPreview
			? Requests_openChatPreview(options, onChatPreviewSuccess)
			: SharedProactive.sessionData.step === SharedProactive.Steps.WaitForEngage
			? (console.info("begin listen for engage"), SharedProactive.openSocket(options, onSocketReceived))
			: SharedProactive.sessionData.step === SharedProactive.Steps.Engaged && engageChatOffer(options);
		},
		SharedProactive = {
		  Mock: !1,
		  APIPath: "appsvcs/rs/svc/",
		  Steps: { LoadOffers: "LoadOffers", OpenSession: "OpenSession", StartChatPreview: "StartChatPreview", WaitForEngage: "WaitForEngage", Engaged: "Engaged" },
		  sessionData: { step: "Unknown", offers: [], selectedProfile: null, options: {}, chatOptions: {}, messages: [] },
		  initialize: function (options) {
			if (((options = options || {}), (this.sharedOptions = options), Five9Modules && ((ProactivePersist = Five9Modules.ProactivePersist), (MessageTypes = Five9Modules.MessageTypes)), "string" != typeof options.restAPI))
			  throw new Error("initialize() Must specify a restAPI");
			if ("string" != typeof options.tenant) throw new Error("initialize() Must specify a tenant");
			if ("string" != typeof options.rootUrl) throw new Error("initialize() Must specify a rootUrl");
			if (void 0 === options.analyticsProvider) throw new Error("initialize() Must specify an analyticsProvider");
			(options.restAPI = SharedProactive.addTrailingSlash(options.restAPI)),
			  (options.rootUrl = SharedProactive.addTrailingSlash(options.rootUrl)),
			  "function" == typeof options.onAccept ? (OnEngageAcceptCallback = options.onAccept) : console.error("onAccept() is required"),
			  delete options.onAccept,
			  "function" == typeof options.onReject ? (OnEngageRejectCallback = options.onReject) : console.error("onReject() is required"),
			  delete options.onReject,
			  "callback" === options.notificationType && "function" != typeof options.offerCallback && (console.error("a callback must be supplied"), (options.notificationType = "notification")),
			  "function" == typeof options.offerCallback && ((OnOfferCallback = options.offerCallback), delete options.offerCallback),
			  !options.useCustomTemplate ||
				("undefined" == typeof f9CustomPreviewOfferTemplate && "undefined" == typeof f9CustomProactiveOfferTemplate) ||
				((NotificationRootSelector = "five9-notification-custom"), (NotificationActiveClass = "active-new"), (ModalRootSelector = "five9-modal-custom")),
			  console.info("analyticsProvider", options.analyticsProvider);
			var data = ProactivePersist.loadData();
			data && "Unknown" !== data.step && ((SharedProactive.sessionData = data), data.step === SharedProactive.Steps.LoadOffers || resume());
		  },
		  supportsFeatures: function () {
			var userAgent = navigator.userAgent;
			return "function" != typeof userAgent.indexOf || (-1 === userAgent.indexOf("MSIE 7") && -1 === userAgent.indexOf("MSIE 8") && -1 === userAgent.indexOf("MSIE 9") && -1 === userAgent.indexOf("MSIE 10"))
			  ? SharedProactive.supportsLocalStorage()
				? !!SharedProactive.supportsWebSockets() || (console.error("browser does not support web sockets"), !1)
				: (console.error("browser does not support local storage"), !1)
			  : (console.error("browser does not support ie10 or below"), !1);
		  },
		  supportsWebSockets: function () {
			return window.WebSocket && 2 === window.WebSocket.CLOSING;
		  },
		  supportsLocalStorage: function () {
			var mod = "modernizr";
			try {
			  return localStorage.setItem(mod, mod), localStorage.removeItem(mod), !0;
			} catch (e) {
			  return !1;
			}
		  },
		  save: function () {
			ProactivePersist.saveData(SharedProactive.sessionData);
		  },
		  loadOffers: function (onSuccess, onError) {
			onSuccess = onSuccess || function () {};
			var options = this.sharedOptions,
			  data = SharedProactive.sessionData;
			if (data && "Unknown" !== data.step)
			  setTimeout(function () {
				onSuccess(data.offers, !1);
			  }, 100);
			else {
			  Requests_loadOffers(
				options,
				function (response) {
				  var offers = response;
				  (SharedProactive.sessionData.step = SharedProactive.Steps.LoadOffers), (SharedProactive.sessionData.options = options), (SharedProactive.sessionData.offers = offers);
				  for (var i = 0; i < offers.length; i++) offers[i].numberOfOfferPerSession = offers[i].proactiveChatNumberOfOffer;
				  SharedProactive.save(), onSuccess(offers, !0);
				},
				onError
			  );
			}
		  },
		  inProgress: function () {
			return !(!SharedProactive.sessionData || "Unknown" === SharedProactive.sessionData.step || SharedProactive.sessionData.step === SharedProactive.Steps.LoadOffers);
		  },
		  triggerOffer: function (offerOptions) {
			console.info("triggerOffer", offerOptions);
			var selectedProfile = (offerOptions = offerOptions || {}).profile;
			if ("Unknown" === SharedProactive.sessionData.step || SharedProactive.sessionData.step === SharedProactive.Steps.LoadOffers) {
			  if ("object" !== _typeof(selectedProfile)) throw new Error("triggerOffer() selectedProfile is required");
			  offerOptions.onServerTerminated && "function" == typeof offerOptions.onServerTerminated && (this.onServerTerminated = offerOptions.onServerTerminated);
			  var chatOptions,
				options = this.sharedOptions;
			  for (var key in ((options.history = offerOptions.history),
			  (options.timeout = selectedProfile.proactiveChatOfferTimeout),
			  (options.question = selectedProfile.proactiveChatQuestion),
			  (options.profiles = selectedProfile.name),
			  (options.groupId = selectedProfile.groupId),
			  (options.tenantId = selectedProfile.tenantId),
			  (options.profile = selectedProfile.name),
			  (options.fields = selectedProfile.fields),
			  void 0 === options.timeout && console.error("triggerOffer() API timeout undefined"),
			  void 0 === options.question && console.error("triggerOffer() API question undefined"),
			  void 0 === options.profiles && console.error("triggerOffer() API profiles undefined"),
			  offerOptions))
				void 0 === options[key] && (options[key] = offerOptions[key]);
			  (SharedProactive.sessionData.options = options),
				((chatOptions = SharedProactive.shallowClone(options)).analytics = [options.analyticsProvider, "true"].join(",")),
				delete chatOptions.restAPI,
				delete chatOptions.onAccept,
				delete chatOptions.onReject,
				delete chatOptions.question,
				delete chatOptions.timeout,
				delete chatOptions.analyticsProvider,
				(SharedProactive.sessionData.chatOptions = chatOptions),
				!selectedProfile.enablePreviewChat && chatOptions.preview && (delete SharedProactive.sessionData.chatOptions.preview, (SharedProactive.sessionData.chatOptions.profile = selectedProfile), (options.preview = !1));
			  var postStatusTrigger = function () {
				selectedProfile.enablePreviewChat
				  ? ((options.preview = !0),
					(options.profile = selectedProfile.name),
					(SharedProactive.sessionData.selectedProfile = selectedProfile),
					(SharedProactive.sessionData.step = SharedProactive.Steps.OpenSession),
					SharedProactive.save(),
					resume())
				  : (SharedProactive.save(), engageChatOffer(options));
			  };
			  if (1 === options.analyticsProvider) postStatusTrigger();
			  else {
				if ((console.info("numberOfOfferPerSession", selectedProfile.numberOfOfferPerSession), selectedProfile.numberOfOfferPerSession <= 0)) return void console.warn("Maximum offers per session reached");
				selectedProfile.numberOfOfferPerSession--,
				  SharedProactive.save(),
				  Requests_status(options, function (response) {
					if ((console.info("onStatusSuccess", response), 0 === response.resultCode)) {
					  if (!1 === response.businessHours.openForBusiness) return console.warn("After business hours");
					  var estimatedWaitMinutes = Math.round(response.ewt / 60);
					  console.log("Estimated wait time is : [%d] minutes", estimatedWaitMinutes), estimatedWaitMinutes <= selectedProfile.proactiveEstimatedWaitTime ? postStatusTrigger() : console.warn("Estimated wait time too long");
					} else console.warn("No agents are logged in");
				  });
			  }
			} else console.warn("Proactive chat offer in progress.  aborting new offer");
		  },
		  abandonPreview: function () {
			SharedProactive.inProgress() &&
			  (console.info("SharedProactive abandonPreview()"),
			  SharedProactive.closeSocket(),
			  (SharedProactive.sessionData.step = SharedProactive.Steps.LoadOffers),
			  SharedProactive.save(),
			  Requests_rejectChatPreviewOffer(SharedProactive.sessionData.options));
		  },
		  triggerCustomerEngageAccept: function () {
			console.info("customer accepted chat request"), SharedProactive.closeSocket(), (SharedProactive.sessionData.step = SharedProactive.Steps.LoadOffers), SharedProactive.save();
			var options = SharedProactive.sessionData.options,
			  chatOptions = SharedProactive.sessionData.chatOptions;
			if (chatOptions.preview) {
			  if (
				((chatOptions.preview.timestamp = Date.now()),
				(chatOptions.preview.ownerId = options.ownerId),
				(chatOptions.preview.displayName = options.displayName),
				(chatOptions.preview.messages = SharedProactive.sessionData.messages),
				options.question && chatOptions.preview.messages.length)
			  ) {
				var welcomeMessage = chatOptions.preview.messages[0],
				  parsedInteraction = JSON.parse(welcomeMessage.interaction);
				(parsedInteraction.content = options.question), (welcomeMessage.interaction = JSON.stringify(parsedInteraction));
			  }
			  if (!SharedProactive.nonEmptyString(options.ownerId)) throw new Error("triggerCustomerEngageAccept() Must specify an ownerId");
			  if ("string" != typeof options.displayName) throw new Error("triggerCustomerEngageAccept() Must specify an displayName");
			  Requests_acceptChatPreviewOffer(options);
			}
			OnEngageAcceptCallback(chatOptions);
		  },
		  triggerCustomerEngageReject: function () {
			console.info("customer rejected chat request"), SharedProactive.closeSocket(), (SharedProactive.sessionData.step = SharedProactive.Steps.LoadOffers), SharedProactive.save();
			var options = SharedProactive.sessionData.options;
			options.preview && Requests_rejectChatPreviewOffer(options), OnEngageRejectCallback();
		  },
		  hideChatOffer: function () {
			var notificationFrame;
			console.log("hideChatOffer"),
			  clearTimeout(SharedProactive.timeoutId),
			  (notificationFrame = document.getElementById(NotificationRootSelector)) && notificationFrame.classList.remove(NotificationActiveClass),
			  (notificationFrame = document.getElementById(ModalRootSelector)) && notificationFrame.classList.remove(NotificationActiveClass);
		  },
		  showChatOffer: function (options, rootSelector, template) {
			if (void 0 === (options = options || {}).timeout) throw new Error("showChatOffer() Must specify a timeout");
			var notificationFrame,
			  onAccept = options.onAccept || function () {},
			  onReject = options.onReject || function () {};
			if (
			  ((notificationFrame = document.getElementById(rootSelector)) &&
				notificationFrame.parentElement &&
				(console.log("removing notificationFrame"),
				notificationFrame.parentElement.removeChild(notificationFrame),
				(notificationFrame = null),
				void 0 !== Five9SocialWidget && Five9SocialWidget.removeEmbeddedFrame(),
				document.body.removeChild(document.getElementById("five9-notification-container"))),
			  !notificationFrame)
			) {
			  ((notificationFrame = document.createElement("div")).id = "five9-notification-container"), (notificationFrame.innerHTML = template), document.body.appendChild(notificationFrame);
			  var acceptButton = notificationFrame.querySelector("#five9_offerAccepted");
			  acceptButton &&
				acceptButton.addEventListener("click", function (e) {
				  SharedProactive.hideChatOffer(), !0 !== options.preview && "function" == typeof gtag && gtag("event", "Proactive Chat Accepted", { event_category: "Five9", event_label: options.profile }), onAccept();
				});
			  var refuseMethod = function (e) {
				  SharedProactive.hideChatOffer(), !0 !== options.preview && "function" == typeof gtag && gtag("event", "Proactive Chat Rejected", { event_category: "Five9", event_label: options.profile }), onReject();
				},
				refuseButton = notificationFrame.querySelector("#five9_offerExit");
			  refuseButton && refuseButton.addEventListener("click", refuseMethod), (refuseButton = notificationFrame.querySelector("#five9_offerRefused")) && refuseButton.addEventListener("click", refuseMethod);
			}
			setTimeout(function () {
			  (notificationFrame = document.getElementById(rootSelector)).classList.add(NotificationActiveClass);
			}, 100),
			  options.timeout &&
				(SharedProactive.timeoutId = setTimeout(function () {
				  SharedProactive.hideChatOffer(), onReject();
				}, 1e3 * options.timeout));
		  },
		  showChatOfferNotification: function (options) {
			if ("string" != typeof (options = options || {}).question) throw new Error("showChatOfferNotification() Must specify a question");
			var headerText,
			  defaultAcceptText,
			  messageText = "",
			  proactiveClass = "five9-proactive five9-notification",
			  displayName = "";
			if (options.preview) {
			  if (((headerText = options.header || "An agent is ready to chat with you"), (messageText = options.question), (proactiveClass += " five9-inverse"), (defaultAcceptText = "Respond"), options.displayName)) {
				var now = formatTime(new Date());
				displayName = options.displayName + ' <span class="display-time">' + now + "</span>";
			  }
			} else (headerText = options.question), (messageText = ""), (defaultAcceptText = "Start Live Chat");
			var template,
			  acceptText = options.acceptText || defaultAcceptText,
			  closeText = options.closeText || "I'm OK for now, thanks.";
			options.useCustomTemplate && options.preview && "undefined" != typeof f9CustomPreviewOfferTemplate
			  ? (template = f9CustomPreviewOfferTemplate)
			  : options.useCustomTemplate && !options.preview && "undefined" != typeof f9CustomProactiveOfferTemplate
			  ? (template = f9CustomProactiveOfferTemplate)
			  : ((template = '<div id="five9-notification" class="' + proactiveClass + '">'),
				(template += '<span class="five9-icon"></span>'),
				(template += '<span id="five9_offerExit" class="five9-exit"></span>'),
				(template += '<span class="five9-text">' + headerText + "</span>"),
				(template += '<div class="five9-agent-text">' + displayName + "</div>"),
				(template += '<div class="five9-message-text">' + messageText + "</div>"),
				(template += '<div id="five9_offerAccepted" class="five9-start-button">' + acceptText + "</div>"),
				(template += '<div id="five9_offerRefused" class="five9-close-button">' + closeText + "</div>"),
				(template += "</div>")),
			  this.showChatOffer(options, NotificationRootSelector, template);
		  },
		  showChatOfferModal: function (options) {
			if ("string" != typeof (options = options || {}).question) throw new Error("showChatOfferModal() Must specify a question");
			var headerText,
			  messageText = "",
			  proactiveClass = "five9-proactive five9-modal",
			  displayName = "";
			if (options.preview) {
			  if (((headerText = options.header || "An agent is ready to chat with you"), (messageText = options.question), (proactiveClass += " five9-inverse"), "Respond", options.displayName)) {
				var now = formatTime(new Date());
				displayName = options.displayName + ' <span class="display-time">' + now + "</span>";
			  }
			} else (headerText = options.question), (messageText = ""), "Start Live Chat";
			var template,
			  acceptText = options.acceptText || "Start Live Chat",
			  closeText = options.closeText || "I'm OK for now, thanks.";
			options.useCustomTemplate && options.preview
			  ? (template = '<div id="five9-modal-custom" class=""><div class="five9-overlay-custom">' + f9CustomPreviewOfferTemplate + "</div></div>")
			  : options.useCustomTemplate && !options.preview
			  ? (template = '<div id="five9-modal-custom" class=""><div class="five9-overlay-custom">' + f9CustomProactiveOfferTemplate + "</div></div>")
			  : ((template = '<div id="five9-modal" class="">'),
				(template += '<div class="five9-overlay">'),
				(template += '<div class="' + proactiveClass + '">'),
				(template += '<span class="five9-icon"></span>'),
				(template += '<span id="five9_offerExit" class="five9-exit"></span>'),
				(template += '<span class="five9-text">' + headerText + "</span>"),
				(template += '<div class="five9-agent-text">' + displayName + "</div>"),
				(template += '<div class="five9-message-text">' + messageText + "</div>"),
				(template += '<div id="five9_offerAccepted" class="five9-start-button">' + acceptText + "</div>"),
				(template += '<div id="five9_offerRefused" class="five9-close-button">' + closeText + "</div>"),
				(template += "</div>"),
				(template += "</div>"),
				(template += "</div>")),
			  this.showChatOffer(options, ModalRootSelector, template);
		  },
		  xhr: function (options, onSuccess, onError) {
			var url = options.url,
			  verb = options.verb || "GET",
			  payload = options.payload || null;
			(onSuccess = onSuccess || function () {}), (onError = onError || function () {});
			try {
			  url = encodeURI(url);
			  var xhr = new XMLHttpRequest();
			  if (null == xhr) return;
			  var onXHRError = function (err) {
				var textStatus = "error";
				try {
				  textStatus = JSON.parse(xhr.responseText);
				} catch (err) {}
				onError(xhr, textStatus, err);
			  };
			  console.info("xhr(" + url + ")"),
				xhr.open(verb, url, !0),
				"GET" !== verb && (xhr.withCredentials = !0),
				xhr.setRequestHeader("Content-Type", "application/json"),
				!1 !== TokenId && xhr.setRequestHeader("Authorization", "Bearer-" + TokenId),
				!1 !== FarmId && xhr.setRequestHeader("farmId", FarmId),
				(xhr.onreadystatechange = function () {
				  if (4 === xhr.readyState)
					if (200 === xhr.status || 204 === xhr.status)
					  try {
						var json = JSON.parse(xhr.responseText);
						onSuccess(json);
					  } catch (err) {
						onXHRError(err);
					  }
					else onXHRError();
				}),
				(xhr.onerror = function () {
				  onXHRError();
				}),
				null === payload ? xhr.send() : xhr.send(JSON.stringify(payload));
			} catch (err) {
			  onError(err);
			}
		  },
		  nonEmptyString: function (s) {
			return "string" == typeof s && "" !== s;
		  },
		  generateAnonEmail: function () {
			return this.generateGuid() + "@anonymous.com";
		  },
		  generateGuid: function () {
			var d = Date.now();
			return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			  var r = (d + 16 * Math.random()) % 16 | 0;
			  return (d = Math.floor(d / 16)), ("x" === c ? r : (7 & r) | 8).toString(16);
			});
		  },
		  addTrailingSlash: function (s) {
			return "/" === s.substr(-1) ? s : s + "/";
		  },
		  shallowClone: function (o) {
			var dest = {};
			for (var key in o) dest[key] = o[key];
			return dest;
		  },
		  objectToQuery: function (o) {
			var a = [];
			for (var key in o) o.hasOwnProperty(key) && a.push(key + "=" + encodeURIComponent(o[key]));
			for (var s = "", i = 0; i < a.length; i++) (s += a[i]), i !== a.length - 1 && (s += "&");
			return s;
		  },
		  formatString: function (s) {
			var args = arguments;
			return s.replace(/\{(\d+)\}/g, function (match, number) {
			  if (((number = parseInt(number, 10) + 1), void 0 !== args[number])) {
				var arg = args[number];
				return "object" === _typeof(arg) && (arg = JSON.stringify(arg)), arg;
			  }
			});
		  },
		  openSocket: function (options, onMessage) {
			console.log("openSocket()", options);
			var restAPI = options.restAPI,
			  socketLocation = restAPI.substring(0, restAPI.length - 1);
			socketLocation = (socketLocation = socketLocation.replace("http://", "")).replace("https://", "");
			var protocol = "ws";
			-1 !== restAPI.indexOf("https") && (protocol = "wss");
			var url = protocol + "://" + socketLocation + "/appsvcs/ws";
			(url += "?Authorization=Bearer-" + options.sessionId),
			  (url += "&farmId=" + options.farmId),
			  console.info("socket url", url),
			  SharedProactive.Mock
				? (setTimeout(function () {
					var data = JSON.stringify({
					  payLoad: {
						interaction:
						  '{"timestamp":1464976104,"content":"Hello","id":"5b6f6298-29b3-11e6-880d-005056a4db18","ownerId":"138","contentType":1,"displayName":"User One","messageId":"0cf0715b-c7e2-4f9b-aef8-587c9033de71","fromType":1}',
						interactionId: "5b6f6298-29b3-11e6-880d-005056a4db18",
						messageId: MessageTypes.MSG_CHAT_AGENT_ACCEPT,
						status: 1,
						userId: "405361",
					  },
					});
					onMessage({ data: data });
				  }, 1e3),
				  setTimeout(function () {
					onMessage({ data: JSON.stringify({ payLoad: { question: "FROM AGENT ENGAGE", engaged: 1 }, context: { messageId: MessageTypes.PREVIEW_ENGAGE_ITEM } }) });
				  }, 2e3))
				: ((this.socket = new WebSocket(url)), (this.socket.onmessage = onMessage));
		  },
		  closeSocket: function () {
			console.log("closeSocket()"), this.socket && (this.socket.close(), delete this.socket);
		  },
		};
	  Five9Modules && (Five9Modules.SharedProactive = SharedProactive);
	})(),
	(Persist = {
	  Version: 1,
	  loadData: function (key) {
		try {
		  var data = localStorage.getItem(key);
		  if (data) {
			try {
			  data = JSON.parse(data);
			} catch (err) {}
			if (data.version === Persist.Version && (("f9-chat-console" === key && "Connecting" === data.step) || Date.now() <= data.date + 17e4))
			  return console.info("Persist:loadData()", key, data), delete data.token, delete data.version, delete data.date, data;
		  }
		} catch (err) {}
	  },
	  saveData: function (key, data) {
		try {
		  (data.date = Date.now()), (data.version = Persist.Version), localStorage.setItem(key, JSON.stringify(data)), console.info("Persist:saveData()", key, data);
		} catch (err) {}
	  },
	  removeData: function (key, data) {
		try {
		  localStorage.removeItem(key), console.info("Persist:removeData()", ProactivePersist.Key, data);
		} catch (err) {}
	  },
	  listenData: function (key, cb) {
		window.addEventListener(
		  "storage",
		  function (e) {
			e.key === key && cb(JSON.parse(e.newValue || "{}"), JSON.parse(e.oldValue || "{}"));
		  },
		  !1
		);
	  },
	}),
	Five9Modules && (Five9Modules.Persist = Persist);
  var Five9 = Five9 || {};
  (Five9.Api = Five9.Api || {}),
	(Five9.Api.EstimatedWaitTime = {
	  fetch: function (options, callback) {
		if (Five9.Api.EstimatedWaitTime.validateOptions(options)) {
		  var url = options.rootUrl + "appsvcs/rs/svc/orgs/estimatedwaittime/1000?tenantName=" + options.tenant + "&campaignName=" + options.profile,
			request = new XMLHttpRequest();
		  request.addEventListener("load", function () {
			var response;
			if ("string" == typeof request.response)
			  try {
				response = JSON.parse(request.response);
			  } catch (ex) {
				callback("Five9.Api.EstimatedWaitTime#fetch response is not valid JSON");
			  }
			else response = request.response;
			(response.profile = options.profile), callback(!1, response);
		  }),
			request.addEventListener("error", function () {
			  callback("Five9.Api.EstimatedWaitTime#fetch received an error status " + request.status + " - " + request.responseText);
			}),
			request.addEventListener("abort", function () {
			  callback("Five9.Api.EstimatedWaitTime#fetch aborted");
			}),
			request.addEventListener("timeout", function () {
			  callback("Five9.Api.EstimatedWaitTime#fetch timeout to url: " + url);
			}),
			request.open("GET", url),
			request.setRequestHeader("Content-type", "application/json"),
			(request.responseType = "json"),
			request.send();
		} else callback("Five9.Api.EstimatedWaitTime#fetch options are invalid");
	  },
	  validateOptions: function (options) {
		var hasTenant = !(!options.tenant || !options.tenant.length),
		  hasProfile = !(!options.profile || !options.profile.length),
		  hasRootUrl = !(!options.rootUrl || !options.rootUrl.length),
		  isValid = hasTenant && hasProfile && hasRootUrl;
		return isValid || console.error("Five9.Api.EstimatedWaitTime#validateOptions invalid options:", "All options:", options, "hasTenant?", hasTenant, "hasProfile?", hasProfile, "hasRootUrl?", hasRootUrl), isValid;
	  },
	  fetchAll: function (options, callback) {
		var error = !1,
		  iterations = 0,
		  responses = [],
		  iterateApi = function (profile) {
			Five9.Api.EstimatedWaitTime.fetch({ tenant: options.tenant, profile: profile, rootUrl: options.rootUrl }, function (err, response) {
			  err ? responses.push({ profile: profile, error: err }) : ((response.profile = profile), responses.push(response)), ++iterations === options.profiles.length && callback(error, responses);
			});
		  },
		  isArray = Array.isArray(options.profiles),
		  hasLength = !!options.profiles.length;
		if (!isArray || !hasLength) return callback((error = "Five9.Api.EstimatedWaitTime#fetchAll expects options.profiles to be an array with length"));
		options.profiles.forEach(function (profile) {
		  iterateApi(profile);
		});
	  },
	  areAnyProfilesOpenForBusiness: function (options, callback) {
		if ((console.log("WIP: areAnyProfs options", options), !Array.isArray(options.profiles) || !options.profiles.length)) return callback("Five9.Api.EstimatedWaitTime#areAnyProfilesOpenForBusiness Invalid profiles");
		var url = [options.rootUrl, "appsvcs/rs/svc/orgs/available_campaigns?tenantName=", options.tenant, "&campaignNames=", options.profiles.join(",")].join(""),
		  request = new XMLHttpRequest();
		request.addEventListener("load", function () {
		  var response;
		  if ("string" == typeof request.response)
			try {
			  response = JSON.parse(request.response);
			} catch (ex) {
			  callback("Five9.Api.EstimatedWaitTime#areAnyProfilesOpenForBusiness response is not valid JSON");
			}
		  else response = request.response;
		  console.log("WIP request.response", request.response),
			Array.isArray(response.availableCampaigns) ? callback(!1, response.availableCampaigns) : callback("Five9.Api.EstimatedWaitTime#areAnyProfilesOpenForBusiness response was not an array");
		}),
		  request.addEventListener("error", function () {
			callback("Five9.Api.EstimatedWaitTime#areAnyProfilesOpenForBusiness received an error status " + request.status + " - " + request.responseText);
		  }),
		  request.addEventListener("abort", function () {
			callback("Five9.Api.EstimatedWaitTime#areAnyProfilesOpenForBusiness aborted");
		  }),
		  request.addEventListener("timeout", function () {
			callback("Five9.Api.EstimatedWaitTime#areAnyProfilesOpenForBusiness timeout to url: " + url);
		  }),
		  request.open("GET", url),
		  request.setRequestHeader("Content-type", "application/json"),
		  (request.responseType = "json"),
		  request.send();
	  },
	}),
	Five9Modules &&
	  (Five9Modules.MessageTypes = {
		MSG_TO_IGNORE_1: 1111,
		MSG_TO_IGNORE_2: 1101,
		MSG_TO_IGNORE_3: 1102,
		MSG_BROWSER_NOT_SUPPORTED: -100,
		MSG_CONNECTION_NOT_AVAILABLE: -101,
		RESULT_SUCCESS: 1,
		RESULT_ERROR: -1,
		TRANSFER_TO_GROUP: 1751,
		TRANSFERT_TO_AGENT: 1752,
		AGENT_TRANSFER_AGENT: 1753,
		INTERACTION_TERMINATED: 19507,
		MSG_CHAT_CLIENT_REQUEST: 17001,
		MSG_CHAT_CLIENT_MESSAGE: 17002,
		MSG_CHAT_CLIENT_TERMINATE: 17003,
		MSG_CHAT_CLIENT_MESSAGE_RECEIVED: 17004,
		MSG_CHAT_CLIENT_TYPING: 17005,
		MSG_NO_SERVICE: 17008,
		PREVIEW_OFFER_ITEM: 19600,
		PREVIEW_ASSIGN_ITEM: 19601,
		PREVIEW_REJECT_ITEM: 19602,
		PREVIEW_ENGAGE_ITEM: 19603,
		PREVIEW_ENGAGE_ACCEPT_ITEM: 19604,
		PREVIEW_ENGAGE_REJECT_ITEM: 19605,
		CUSTOMER_CONTACT_UPDATE: 19608,
		PREVIEW_OFFER_CHERRY_ITEM: 19700,
		PREVIEW_LOCK_CHERRY_ITEM: 19701,
		MSG_CHAT_AGENT_ACCEPT: 18e3,
		MSG_CHAT_AGENT_MESSAGE: 18001,
		MSG_CHAT_AGENT_TERMINATE: 18002,
		MSG_CHAT_AGENT_MESSAGE_TO_AGENT: 18004,
		MSG_CHAT_AGENT_TYPING: 18005,
		MSG_CHAT_AGENT_MESSAGE_RECEIVED: 18007,
		MSG_CHAT_AGENT_ADD_AGENT_TO_CHAT: 18008,
		MSG_CHAT_AGENT_REMOVE_AGENT_FROM_CHAT: 18009,
		MSG_CHAT_SIGHTCALL_ESCALATION: 18012,
		MSG_CHAT_SIGHTCALL_VIDEO_ACTIVATED: 18013,
		MSG_CHAT_SIGHTCALL_VIDEO_TERMINATED: 18014,
		MSG_CHAT_SIGHTCALL_CANCELED: 18015,
		MSG_CHAT_AGENT_CONFERENCE_AGENT: 18020,
		MSG_CHAT_AGENT_COMFORT_MESSAGE: 18021,
		MSG_CHAT_AUTO_CLOSE: 18022,
		MSG_TEXT: 1,
		MSG_HTML: 2,
		MSG_VOICE: 3,
		MSG_VIDEO: 4,
		MSG_FILE: 5,
		STATE_PENDING: 1,
		STATE_DELIVERED: 2,
		STATE_TYPING: 3,
		STATE_DELETING: 4,
		FROM_AGENT: 1,
		FROM_CLIENT: 2,
		FROM_SERVER: 3,
		FROM_TYPING: 4,
		CHAT_STATE_ACTIVE: 1,
		CHAT_STATE_TEMINATED: 2,
		IN: 1,
		OUT: 2,
		getDescription: function (messageId) {
		  switch (messageId) {
			case this.MSG_CHAT_CLIENT_REQUEST:
			  return "ChatClientRequest";
			case this.MSG_CHAT_CLIENT_MESSAGE:
			  return "ChatClientMessage";
			case this.MSG_CHAT_CLIENT_TERMINATE:
			  return "ChatClientTerminate";
			case this.MSG_CHAT_CLIENT_MESSAGE_RECEIVED:
			  return "ChatClientMessageReceived";
			case this.MSG_CHAT_CLIENT_TYPING:
			  return "ChatClientTyping";
			case this.PREVIEW_OFFER_ITEM:
			  return "PREVIEW_OFFER_ITEM";
			case this.PREVIEW_ASSIGN_ITEM:
			  return "PREVIEW_ASSIGN_ITEM";
			case this.PREVIEW_REJECT_ITEM:
			  return "PREVIEW_REJECT_ITEM";
			case this.PREVIEW_ENGAGE_ITEM:
			  return "PREVIEW_ENGAGE_ITEM";
			case this.PREVIEW_ENGAGE_ACCEPT_ITEM:
			  return "PREVIEW_ENGAGE_ACCEPT_ITEM";
			case this.PREVIEW_ENGAGE_REJECT_ITEM:
			  return "PREVIEW_ENGAGE_REJECT_ITEM";
			case this.MSG_CHAT_AGENT_ACCEPT:
			  return "ChatAgentAccept";
			case this.MSG_CHAT_AGENT_MESSAGE:
			  return "ChatAgentMessage";
			case this.MSG_CHAT_AGENT_TERMINATE:
			  return "ChatAgentTerminate";
			case this.MSG_CHAT_AGENT_MESSAGE_TO_AGENT:
			  return "ChatAgentToAgent";
			case this.MSG_CHAT_AGENT_TYPING:
			  return "ChatAgentTyping";
			case this.MSG_CHAT_AGENT_MESSAGE_RECEIVED:
			  return "ChatAgentMessageReceived";
			case this.MSG_CHAT_AGENT_ADD_AGENT_TO_CHAT:
			  return "AddAgentToConference";
			case this.MSG_CHAT_AGENT_REMOVE_AGENT_FROM_CHAT:
			  return "RemoveAgentFromConference";
			case this.MSG_CHAT_AGENT_COMFORT_MESSAGE:
			  return "ChatAgentComfortMessage";
			case this.MSG_CHAT_AUTO_CLOSE:
			  return "ChatAutoCloseWarning";
			default:
			  return "Unknown message id [" + messageId + "]";
		  }
		},
	  }),
	(ChatModel = {
	  Key: "f9-chat-console",
	  id: !1,
	  Steps: { Unknown: "Unknown", Information: "Information", Connecting: "Connecting", Conversation: "Conversation", Finished: "Finished" },
	  PreviewSteps: { Unknown: "Unknown", EditContact: "EditContact", Complete: "Complete" },
	  SystemFields: ["profiles", "name", "email", "question"],
	  data: {
		compositeKey: { domainKey: "", namespace: "" },
		step: "Unknown",
		previewStep: "Unknown",
		session: {},
		information: { profile: "", name: "", email: "", subject: "", content: "", customFields: {}, playSoundOnMessage: null, highContrastEnabled: null, fontSize: null },
		conversation: { transferring: !1, addEWTMessage: !1, connectingMessage: "", message: "", messages: [], messageSequence: 1 },
	  },
	  SaveDisabled: !1,
	  setDomainKey: function (domainKey) {
		ChatModel.data.compositeKey.domainKey = domainKey;
	  },
	  isDomainKey: function () {
		return !!ChatModel.data.compositeKey.domainKey && !!ChatModel.data.compositeKey.domainKey.length;
	  },
	  setNamespace: function (ns) {
		ChatModel.data.compositeKey.namespace = ns;
	  },
	  compositeKey: function () {
		var domainKeyPart = btoa(ChatModel.data.compositeKey.domainKey),
		  namespacePart = btoa(ChatModel.data.compositeKey.namespace),
		  compositeKey = ChatModel.Key + "-" + domainKeyPart + namespacePart;
		return compositeKey;
	  },
	  getStep: function () {
		return ChatModel.data.step;
	  },
	  setStep: function (val) {
		ChatModel.data.step = val;
	  },
	  isTransferring: function () {
		return ChatModel.data.conversation.transferring;
	  },
	  setTransferring: function (val) {
		ChatModel.data.conversation.transferring = val;
	  },
	  addEWTMessage: function () {
		return ChatModel.data.conversation.addEWTMessage;
	  },
	  setAddEWTMessage: function (val) {
		ChatModel.data.conversation.addEWTMessage = val;
	  },
	  hasConnectingMessage: function () {
		return "" !== ChatModel.data.conversation.connectingMessage;
	  },
	  getConnectingMessage: function () {
		return ChatModel.data.conversation.connectingMessage;
	  },
	  setConnectingMessage: function (val) {
		ChatModel.data.conversation.connectingMessage = val;
	  },
	  getMessages: function () {
		return ChatModel.data.conversation.messages;
	  },
	  addMessage: function (message) {
		ChatModel.data.conversation.messages.push(message);
	  },
	  updateMessage: function (id, text) {
		ChatModel.data.conversation.messages
		  .filter(function (m) {
			return m.messageId === id;
		  })
		  .forEach(function (m) {
			m.messageContent = text;
		  });
	  },
	  getMessageSequence: function () {
		return ChatModel.data.conversation.messageSequence;
	  },
	  incrementMessageSequence: function () {
		ChatModel.data.conversation.messageSequence++;
	  },
	  getPreviewStep: function () {
		return ChatModel.data.previewStep;
	  },
	  setPreviewStep: function (val) {
		ChatModel.data.previewStep = val;
	  },
	  getPlaySound: function () {
		return ChatModel.data.information.playSoundOnMessage;
	  },
	  setPlaySound: function (playSound) {
		ChatModel.data.information.playSoundOnMessage = playSound;
	  },
	  getHighContrastEnabled: function () {
		return ChatModel.data.information.highContrastEnabled;
	  },
	  setHighContrastEnabled: function (enabled) {
		ChatModel.data.information.highContrastEnabled = enabled;
	  },
	  getFontSize: function () {
		return ChatModel.data.information.fontSize;
	  },
	  setFontSize: function (size) {
		ChatModel.data.information.fontSize = size;
	  },
	  addListeners: function () {
		$("#information-page #question").change(ChatModel.saveData), $(document.body).on("change", "#information-page .form-control input", ChatModel.saveData), $("#conversation-page #input-message").change(ChatModel.saveData);
	  },
	  modelToView: function () {
		ChatModel.data.step !== ChatModel.Steps.Information && gSession.setSession(ChatModel.data.session),
		  $("#information-page #name").val(ChatModel.data.information.name),
		  $("#information-page #email").val(ChatModel.data.information.email),
		  $("#information-page #question").val(ChatModel.data.information.question),
		  $("#conversation-page #input-message").val(ChatModel.data.conversation.message);
		var customFields = ChatModel.data.information.customFields;
		if ("object" === _typeof(customFields))
		  for (var key in customFields)
			if (customFields.hasOwnProperty(key)) {
			  var val = customFields[key].val;
			  $('input[id="' + key + '"]').val(val);
			}
	  },
	  viewToModel: function () {
		(ChatModel.data.session = gSession.getSession()),
		  (ChatModel.data.information.profile = $("#profiles").val()),
		  (ChatModel.data.information.name = $("#information-page #name").val()),
		  (ChatModel.data.information.email = $("#information-page #email").val()),
		  (ChatModel.data.information.question = $("#information-page #question").val()),
		  (ChatModel.data.conversation.message = $("#conversation-page #input-message").val()),
		  $(".form-control").each(function () {
			var key = this.id.split("-")[0];
			if (-1 === $.inArray(key, ChatModel.SystemFields)) {
			  var $input = $('input[id="' + key + '"]'),
				val = $input.val();
			  $input.length && ChatModel.data.information.customFields && (ChatModel.data.information.customFields[key] = { val: val });
			}
		  });
	  },
	  loadData: function () {
		if (!ChatModel.isDomainKey()) return !1;
		var data = Persist.loadData(ChatModel.compositeKey());
		if (data && data.step !== ChatModel.Steps.Finished) {
		  console.info("Persist: fill views from saved data"), (ChatModel.data = data);
		  var messagesData = data.conversation.messages;
		  data.conversation.messages = [];
		  for (var messages = data.conversation.messages, i = 0; i < messagesData.length; i++) {
			var message = messagesData[i],
			  chatMessage = new ChatMessage(
				message.id,
				message.messageId,
				message.messageType,
				message.messageDirection,
				message.messageContent,
				message.originatorType,
				message.originatorEmail,
				message.originatorName,
				message.destinators,
				message.contentType,
				message.confirmation,
				message.referenceId,
				message.date,
				message.removeUserNames
			  );
			messages.push(chatMessage);
		  }
		  return "undefined" != typeof gSession && ChatModel.modelToView(), !0;
		}
		return !1;
	  },
	  disableSave: function () {
		ChatModel.SaveDisabled = !0;
	  },
	  saveData: function () {
		if (ChatModel.SaveDisabled || !ChatModel.isDomainKey()) return !1;
		ChatModel.viewToModel(), Persist.saveData(ChatModel.compositeKey(), ChatModel.data), App.sendMessageToParent({ action: "set-localstorage", value: ChatModel.data, key: ChatModel.compositeKey() });
	  },
	  done: function () {
		ChatModel.setStep(ChatModel.Steps.Finished), ChatModel.saveData();
	  },
	  allowMinimize: function (data) {
		return !0;
	  },
	  reset: function () {
		ChatModel.setStep(ChatModel.Steps.Unknown), ChatModel.saveData();
	  },
	}),
	Five9Modules && (Five9Modules.ChatModel = ChatModel),
	(EmailModel = {
	  Key: "f9-email-console",
	  Steps: { Unknown: "Unknown", Information: "Information", Finished: "Finished" },
	  SystemFields: ["profiles", "name", "email", "subject"],
	  data: { step: "Unknown", information: { profile: "", name: "", email: "", subject: "", content: "", customFields: {} }, hiddenFields: [] },
	  getStep: function () {
		return EmailModel.data.step;
	  },
	  setStep: function (val) {
		EmailModel.data.step = val;
	  },
	  setHiddenFields: function (fields) {
		EmailModel.data.hiddenFields = fields;
	  },
	  addListeners: function () {
		$("#information-page #name").change(EmailModel.saveData),
		  $("#information-page #email").change(EmailModel.saveData),
		  $("#information-page #subject").change(EmailModel.saveData),
		  $("#information-page #content").change(EmailModel.saveData);
	  },
	  modelToView: function () {
		gEmailEventHandler.setProfileName(EmailModel.data.information.profile),
		  $("#information-page #name").val(EmailModel.data.information.name),
		  $("#information-page #email").val(EmailModel.data.information.email),
		  $("#information-page #subject").val(EmailModel.data.information.subject),
		  $("#information-page #content").val(EmailModel.data.information.content),
		  EmailModel.data.hiddenFields.length &&
			EmailModel.data.hiddenFields.forEach(function (field) {
			  $("#" + field + "-form-control").hide();
			});
		var customFieldKeys = Object.getOwnPropertyNames(EmailModel.data.information.customFields);
		customFieldKeys.length &&
		  customFieldKeys.forEach(function (key) {
			$('input[id="' + key + '"]').val(EmailModel.data.information.customFields[key]);
		  });
	  },
	  viewToModel: function () {
		(EmailModel.data.information.profile = $("#profiles").val()),
		  (EmailModel.data.information.name = $("#information-page #name").val()),
		  (EmailModel.data.information.email = $("#information-page #email").val()),
		  (EmailModel.data.information.subject = $("#information-page #subject").val()),
		  (EmailModel.data.information.content = $("#information-page #content").val()),
		  $(".custom-fields .form-control input").each(function (ind, el) {
			var key = $(el).attr("id"),
			  val = $(el).val();
			EmailModel.data.information.customFields[key] = val;
		  });
	  },
	  loadData: function () {
		var data = Persist.loadData(EmailModel.Key);
		return !(!data || data.step === EmailModel.Steps.Finished || (console.info("Persist: fill views from saved data"), (EmailModel.data = data), EmailModel.modelToView(), 0));
	  },
	  saveData: function () {
		EmailModel.viewToModel(), Persist.saveData(EmailModel.Key, EmailModel.data);
	  },
	  done: function () {
		EmailModel.setStep(EmailModel.Steps.Finished), EmailModel.saveData();
	  },
	  allowMinimize: function (data) {
		return !0;
	  },
	}),
	Five9Modules && (Five9Modules.EmailModel = EmailModel);
  